import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Layout from 'components/layout';
import { darkTheme } from 'constants/theme';

export default function Projects({ data }) {
  const projectsContent = data.projectContent;
  return (
    <Layout theme={darkTheme}>
      <div className="spacious">
        <img
          src="../img/running-sequencer-inverted.svg"
          alt="Running Sequencer"
          className="rounded-corners"
        />
        <div className="u-full-width">
          <h2>{projectsContent.frontmatter.title}</h2>
        </div>
        <div className="row">
          <div className="six columns">
            {data.allProjects.edges.map(({ node }) => (
              <div key={node.id}>
                <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="offset-by-six six columns">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in
            mattis lacus, et condimentum orci. Nam id elit nec odio tincidunt
            congue. Nam porta turpis in ornare cursus.
          </div>
        </div>
      </div>
    </Layout>
  );
}

Projects.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query Projects {
    projectContent: markdownRemark(
      fields: { slug: { glob: "/pages/projects/" } }
    ) {
      htmlAst
      frontmatter {
        title
      }
    }
    allProjects: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { slug: { glob: "/projects/*/" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          htmlAst
        }
      }
    }
  }
`;
